import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from '@/utils/createReduxWebStorage'; //USING CUSTOM STORAGE TO AVOID SERVER-SIDE ERRORS
import { combineReducers } from 'redux';

import { themeReducer } from '@/features/themeSwitcher/themeSwitcherSlice';
import { indexHoldersModalReducer } from '@/features/indexHoldersModal/indexHoldersModalSlice';
import { activeChartSerieReducer } from '@/features/activeChartSerie/activeChartSerie';
import { arcanisStrategySlideControlReducer } from '@/features/arcanisStrategySlideControl/arcanisStrategySlideControl';
import { menuLinksReducer } from '@/features/menuLinks/menuLinksSlice';
import { indexHoldersQuarterTabsReducer } from '@/features/indexHoldersQuarterTabs/indexHoldersQuarterTabs';
import { userConsentSliceReducer } from './features/userConsent/userConsentSlice';
import { privacyPolicySliceReducer } from './features/privacyPolicy/privacyPolicySlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['theme', 'userConsent'], 
};


const rootReducer = combineReducers({
  theme: themeReducer,
  indexHoldersModal: indexHoldersModalReducer,
  activeChartSerie: activeChartSerieReducer,
  arcanisStrategySlideControl: arcanisStrategySlideControlReducer,
  menuLinks: menuLinksReducer,
  indexHoldersQuarterTabs: indexHoldersQuarterTabsReducer,
  userConsent: userConsentSliceReducer,
  privacyPolicyModal: privacyPolicySliceReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const makeStore = () => {
  const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });

  const persistor = persistStore(store);

  return { store, persistor };
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = ReturnType<typeof makeStore>['store']['dispatch'];
export type AppStore = ReturnType<typeof makeStore>;